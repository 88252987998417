<template>
  <a
    role="button"
    class="location float-right mr-2"
    href="javascript:void(0);"
    @click="showLocationModal"
    >Update Location
    <b-modal
      ref="user-location-modal"
      header-class="border-0 h-25 pb-0"
      footer-class="border-0"
      body-class="p-0"
    >
      <template v-slot:modal-header="{ close }">
        <div class="container-fluid">
          <div class="row">
            <div class="col-9"></div>
            <div class="col-3">
              <a
                role="button"
                href="javascript:void(0);"
                @click="close"
                class="float-right"
              >
                <i class="fas fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <div class="my-5 text-center">
        <button class="btn btn-lg btn-primary" @click="getLocation">
          <i class="fas fa-location-arrow"></i> Use my location
        </button>
        <h4 class="my-4">or</h4>
        <ParkSearch
          placeholder="Enter a location..."
          :includeMapBox="true"
          :includeParks="false"
          style="width: 400px"
          @latLongUpdated="setLocation"
        />
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </a>
</template>

<script>
import ParkSearch from "@/components/search/ParkSearch.vue";

export default {
  name: "UserLocationModal",
  components: {
    ParkSearch
  },
  methods: {
    showLocationModal() {
      this.$refs["user-location-modal"].show();
    },
    getLocation() {
      this.$emit("getLocation");
      this.$refs["user-location-modal"].hide();
    },
    setLocation() {
      this.$refs["user-location-modal"].hide();
      this.$emit("setLocation");
    }
  },
  mounted() {}
};
</script>

<style scoped>
.location {
  color: #1078a8 !important;
}
</style>
