<template>
  <div>
    <div class="tabs">
      <ul
        class="nav nav-tabs flex-column flex-sm-row"
        role="tabpanel"
        id="locationTabs"
      >
        <li class="nav-item flex-sm-fill text-sm-center" role="tablist">
          <a
            class="nav-link active"
            data-toggle="tab"
            role="tab"
            aria-selected="false"
            id="parkDetailsTab"
            href="#parkDetails"
            >Park Details</a
          >
        </li>

        <li class="nav-item flex-sm-fill text-sm-center" role="tablist">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            aria-selected="true"
            id="parkPhotosTab"
            href="#parkPhotos"
            >Park Photos</a
          >
        </li>
        <li class="nav-item flex-sm-fill text-sm-center" role="tablist">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            aria-selected="false"
            id="bookingDetailsTab"
            href="#bookingDetails"
            >Booking Details</a
          >
        </li>
        <li class="nav-item flex-sm-fill text-sm-center" role="tablist">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            aria-selected="false"
            id="activityDetailsTab"
            href="#activityDetails"
            >Activities & Amenities</a
          >
        </li>
      </ul>
    </div>
    <div class="tab-content" id="locationTabContent" v-if="location.id">
      <div
        class="tab-pane show active"
        id="parkDetails"
        role="tabpanel"
        aria-labelledby="parkDetailsTab"
      >
        <div class="row mx-0">
          <div
            class="col-12 col-sm-12 col-md-3"
            v-if="location.importantDates != ''"
          >
            <div class="important-season-dates text-center my-3">
              <div class="header">IMPORTANT SEASON DATES</div>
              <div v-html="location.importantDates"></div>
            </div>
          </div>
          <div
            tabindex="0"
            :class="
              location.importantDates != ''
                ? 'scrollable-section col-12 col-sm-12 col-md-9'
                : 'scrollable-section col-12 col-sm-12 col-md-12 pl-4'
            "
          >
            <p class="pt-3">
              <b>General Description:</b>
              <br />
              <span v-html="generalDescription"></span>
            </p>
            <div class="mb-3">
              <b>Directions:</b>
              <br />
              <div v-if="location.directions.length <= 1500">
                <span v-html="location.directions"></span>
              </div>
              <div v-else>
                <span
                  v-html="
                    showMoreDirections == true
                      ? directions + ' '
                      : directions.substr(0, 1500) + '... '
                  "
                ></span>
                <span
                  @click="showMoreDirections = !showMoreDirections"
                  class="text-primary"
                  style="text-decoration: underline; cursor: pointer;"
                  >{{
                    showMoreDirections == true ? "Show Less" : "Show More"
                  }}</span
                >
              </div>
            </div>
            <p class="mb-3">
              <b>Address:</b>
              <br />
              {{ location.street1 }}
              <br />
              <span v-if="location.street2">
                {{ location.street2 }}
                <br />
              </span>
              {{ location.city }}, {{ location.state }}
              {{ location.zipcode | zipCode }}
            </p>
            <p class="mb-3">
              <b>GPS Info:</b>
              <br />
              {{ location.latitude }}, {{ location.longitude }}
              <br />
              {{ location.latitude | convertDMSLat }}
              {{ location.longitude | convertDMSLong }}
            </p>
            <p class="my-0">
              <b>Phone:</b>
              {{ location.phoneNumber | formatPhoneNumber }}
            </p>
            <p class="my-0" v-if="location.faxNumber">
              <b>Fax:</b>
              {{ location.faxNumber | formatPhoneNumber }}
            </p>
            <p class="my-0">
              <b>Primary Contact Name:</b>
              {{ location.primaryContactName }}
            </p>
            <p class="my-0">
              <b>Primary Contact Email:</b>
              {{ location.primaryContactEmail }}
            </p>
            <div
              v-if="
                location.secondaryContactName &&
                  location.secondaryContactName !== 'n/a'
              "
            >
              <p class="my-0">
                <b>Secondary Contact Name:</b>
                {{ location.secondaryContactName }}
              </p>
            </div>
            <div
              v-if="
                location.secondaryContactEmail &&
                  location.secondaryContactEmail !== 'n/a'
              "
            >
              <p class="my-0">
                <b>Secondary Contact Email:</b>
                {{ location.secondaryContactEmail }}
              </p>
            </div>
            <p class="mb-3">
              <b>Website:</b>
              {{ location.url }}
            </p>
            <p class="my-0" v-if="location.annualVisitors">
              <b>Annual Visitors:</b>
              {{ location.annualVisitors }}
            </p>
            <p class="my-0" v-if="location.acreage">
              <b>Acreage:</b>
              {{ location.acreage }} acres
            </p>
            <p class="my-0" v-if="location.elevation">
              <b>Elevation:</b>
              {{ location.elevation }} ft.
            </p>
            <p class="my-0">
              <b>Region:</b>
              {{ location.region.longName }}
            </p>
            <p class="mb-3">
              <b>Timezone:</b>
              {{ location.timezone }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="tab-pane"
        id="parkPhotos"
        role="tabpanel"
        aria-labelledby="parkPhotosTab"
      >
        <div class="my-3 mx-3" v-if="location.images.length !== 0">
          <LocationHeader :location="location" />
        </div>
        <div class="my-3 mx-3" v-else>
          <img :src="tenant.defaultLocationImageUrl" class="w-100" />
        </div>
      </div>
      <div
        class="tab-pane scrollable-section"
        id="bookingDetails"
        role="tabpanel"
        aria-labelledby="bookingDetailsTab"
      >
        <p class="m-3">
          <b>Reservation Information:</b>
          <br />
          <span v-html="reservationInfo" />
        </p>
      </div>
      <div
        class="tab-pane scrollable-section"
        id="activityDetails"
        role="tabpanel"
        aria-labelledby="activityDetailsTab"
      >
        <div class="row mx-0">
          <div class="col col-4">
            <p class="pt-3 m-0">
              <b>Within Facility:</b>
            </p>
            <p v-for="(poiName, index) in closePois" :key="index" class="my-0">
              {{ poiName }}
            </p>
          </div>
          <div class="col col-4">
            <p class="pt-3 m-0">
              <b>Within 10 Miles:</b>
            </p>
            <p
              v-for="(poiName, index) in furtherPois"
              :key="index"
              class="my-0"
            >
              {{ poiName }}
            </p>
          </div>
          <div class="col col-4">
            <p class="pt-3 m-0">
              <b>Greater Than 10 Miles:</b>
            </p>
            <p
              v-for="(poiName, index) in furthestPois"
              :key="index"
              class="my-0"
            >
              {{ poiName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationHeader from "@/components/parkdetails/LocationHeader.vue";

export default {
  name: "LocationInformation",
  props: {
    location: Object,
    pois: Array
  },
  components: {
    LocationHeader
  },
  data() {
    return {
      showMoreDirections: false
    };
  },
  computed: {
    directions() {
      if (
        this.location.directions.substr(0, 3).includes("<p>") &&
        this.location.directions.substr(-5).includes("</p>")
      ) {
        return this.location.directions.substring(
          3,
          this.location.directions.length - 5
        );
      } else {
        return this.location.directions;
      }
    },
    closePois() {
      const filtered = this.pois
        .filter(x => x.distanceDescriptionId === 1)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      return [...new Set(filtered.map(x => x.name))];
    },
    furtherPois() {
      const filtered = this.pois
        .filter(x => x.distanceDescriptionId === 2)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      return [...new Set(filtered.map(x => x.name))];
    },
    furthestPois() {
      const filtered = this.pois
        .filter(x => x.distanceDescriptionId === 3)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      return [...new Set(filtered.map(x => x.name))];
    },
    generalDescription() {
      return this.location?.locationDescriptions?.find(
        x => x.title === "General Description"
      )?.description;
    },
    reservationInfo() {
      return this.location?.locationDescriptions?.find(
        x => x.title === "Reservation Info"
      )?.description;
    },
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    }
  }
};
</script>

<style scoped>
.important-season-dates {
  border: 2px solid #dcdcdc;
  border-radius: 0.3rem;
  padding: 0.4rem;
}
.important-season-dates .header {
  color: #1078a8;
  font-weight: bolder;
  font-size: 0.875rem;
}
.important-season-dates .item-header {
  font-weight: bolder;
  font-size: 0.875rem;
}
.scrollable-section {
  height: 440px;
  overflow-y: auto;
}
</style>
