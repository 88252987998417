<template>
  <div class="parent">
    <nav class="results bg-light" :class="sidebarToggleClass">
      <div class="bread-crumb m-2">
        <a
          class="text-primary"
          @click="$router.push('/').catch(() => {})"
          href="javascript:void(0);"
          >HOME</a
        >
        > SEARCH RESULTS
      </div>
      <div
        class="search-string mt-2 ml-2"
        v-if="!(alert && alert.layer === 'searchList')"
      >
        {{ searchResults.length }} Search results
        {{ searchTerm !== null ? 'near "' + searchTerm + '"' : "" }}
        <UserLocationModal
          @setLocation="setLocation"
          @getLocation="getLocation"
        />
      </div>
      <div
        class="card bg-primary text-white sort-by p-2 my-auto mx-auto"
        v-if="alert && alert.layer === 'searchList'"
      >
        {{ alert.message }}
      </div>
      <div v-if="filters.length > 0" class="ml-2 mb-1">
        <a
          class="clear-filters pr-1"
          href="javascript:void(0);"
          @click="clearFilters"
          >Clear All Filters</a
        >
      </div>
      <div class="filter-badge-list ml-2 mb-2">
        <span
          class="badge badge-dark mr-1 mb-1"
          v-for="(item, index) in filters"
          :key="index"
        >
          {{ item.name }}
          <a href="javascript:void(0);" @click="clearFilter(item)">
            <i class="fas fa-times ml-1 clear-x"></i>
          </a>
        </span>
      </div>
      <div class="sort-by ml-2 mb-2" v-if="searchResults.length > 0">
        <select
          name="sort_by"
          id="sort_by"
          class="sort-by"
          aria-label="Sort Results"
          v-model="sortBy"
          @change="sortHandler"
        >
          <option value="name">Sort By: Park Name</option>
          <option value="distance">Sort By: Closest Distance</option>
        </select>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item" v-for="item in searchResults" :key="item.name">
          <ParkSearchResultsListItem
            :park="item"
            @hoverPark="hoverParkUpdated"
            @locationClicked="locationClicked"
            :isSelected="selectedLocationId == item.locationId"
          />
        </li>
      </ul>
    </nav>
    <div class="hide">
      <img
        class="defaultParkImage"
        src="@/assets/images/tenant/DefaultParkImage_grayscale.png"
        alt="Default Park Image"
      />
    </div>
  </div>
</template>

<script>
import ParkSearchResultsListItem from "@/components/search/ParkSearchResultsListItem.vue";
import UserLocationModal from "@/components/search/UserLocationModal.vue";

export default {
  name: "ParkSearchResultsList",
  components: {
    ParkSearchResultsListItem,
    UserLocationModal
  },
  data() {
    return {
      parkItems: [],
      sidebarToggleClass: "",
      sidebarToggleIconClass: "fa-angle-left",
      sortBy: "name"
    };
  },
  props: {
    searchResults: Array,
    spotTypes: Array,
    regions: Array,
    pointsOfInterest: Array,
    attributes: Array,
    selectedLocationId: Number
  },
  computed: {
    searchTerm() {
      return this.$store.getters["search/searchTerm"];
    },
    alert: {
      cache: false,
      get() {
        return this.$store.getters["alert/errorAlert"];
      }
    },
    latitude() {
      return this.$store.getters["search/selectedLatitude"];
    },
    longitude() {
      return this.$store.getters["search/selectedLongitude"];
    },
    filters() {
      let filters = [];
      filters = filters.concat(
        this.getMappedFilters(
          "search/selectedSpotTypes",
          "search/setSelectedSpotTypes",
          "name",
          "spotTypes"
        )
      );
      filters = filters.concat(
        this.getMappedFilters(
          "search/selectedRegions",
          "search/setSelectedRegions",
          "longName",
          "regions"
        )
      );
      filters = filters.concat(
        this.getMappedFilters(
          "search/selectedPointsOfInterest",
          "search/setSelectedPointsOfInterest",
          "name",
          "pointsOfInterest"
        )
      );
      filters = filters.concat(
        this.getMappedFilters(
          "search/selectedAttributes",
          "search/setSelectedAttributes",
          "name",
          "attributes"
        )
      );
      return filters;
    }
  },
  methods: {
    locationClicked(e) {
      this.$emit("locationClicked", e);
    },
    sortHandler() {
      if (this.sortBy === "distance") {
        this.getLocation();
      } else {
        this.$store.commit("search/setSelectedLatitude", null);
        this.$store.commit("search/setSelectedLongitude", null);
        this.$store.commit("search/setSearchTerm", null);
        this.setLocation();
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.setLatLong,
          this.locationErrHandler
        );
      } else {
        this.locationErrHandler({
          message: "Geolocation is not supported by this browser."
        });
      }
    },
    setLatLong(position) {
      this.$store.commit(
        "search/setSelectedLatitude",
        position.coords.latitude
      );
      this.$store.commit(
        "search/setSelectedLongitude",
        position.coords.longitude
      );
      this.$store.commit("search/setSearchTerm", null);
      this.setLocation();
      this.$emit("userLocation", true);
    },
    locationErrHandler(err) {
      this.$store.commit("alert/setErrorAlert", {
        type: "alert-danger",
        message: err.message,
        layer: "public"
      });
    },
    clearFilters() {
      this.$store.commit("search/setSelectedSpotTypes", []);
      this.$store.commit("search/setSelectedRegions", []);
      this.$store.commit("search/setSelectedPointsOfInterest", []);
      this.$store.commit("search/setSelectedAttributes", []);
      this.$store.commit("search/setSearchDate", {
        key: "startDate",
        value: null
      });
      this.$store.commit("search/setSearchDate", {
        key: "endDate",
        value: null
      });
    },
    toggleSidebar() {
      if (this.sidebarToggleClass == "toggled") {
        this.sidebarToggleClass = "";
        this.sidebarToggleIconClass = "fa-angle-left";
        this.columnVal = "col-3";
      } else {
        this.sidebarToggleClass = "toggled";
        this.sidebarToggleIconClass = "fa-angle-right";
        this.columnVal = "";
      }
    },
    getMappedFilters(getter, setter, nameProperty, mappingList) {
      let selectedFilterIds = this.$store.getters[getter];
      if (!selectedFilterIds) {
        return [];
      }

      if (typeof selectedFilterIds[0] === "object") {
        selectedFilterIds = selectedFilterIds.map(x => x.id);
      }

      const selectedFilters = this[mappingList].filter(x =>
        selectedFilterIds.includes(x.id)
      );
      return selectedFilters.map(x => {
        return {
          name: x[nameProperty],
          id: x.id,
          setter: setter,
          getter: getter
        };
      });
    },
    clearFilter(item) {
      const storeFilters = this.$store.getters[item.getter];

      if (typeof storeFilters[0] === "object") {
        this.$store.commit(
          item.setter,
          storeFilters.filter(x => x.id !== item.id)
        );
        return;
      }

      this.$store.commit(
        item.setter,
        storeFilters.filter(x => x !== item.id)
      );
    },
    hoverParkUpdated(locationId) {
      this.$emit("hoverPark", locationId);
    },
    setSortBy() {
      let latitude = this.$store.getters["search/selectedLatitude"];
      let longitude = this.$store.getters["search/selectedLongitude"];
      if (latitude != null && longitude != null) {
        this.sortBy = "distance";
      }
    },
    setLocation() {
      this.$emit("sort");
    }
  },
  watch: {
    latitude() {
      this.setSortBy();
    }
  },
  created() {
    this.setSortBy();
  }
};
</script>

<style scoped>
@media (max-width: 700px) {
  .results {
    position: inherit;
  }
}

@media (min-width: 600px) {
  .results {
    position: absolute;
  }
}
.results {
  z-index: 10;
  min-width: 350px;
  height: 900px;
  box-shadow: 2px 3px 3px 1px rgb(0, 0, 0);
  overflow-x: hidden;
  padding: 1em;
}

.toggled {
  margin-left: -317px;
}

.results-toggle {
  overflow: visible;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #999;
  text-align: right;

  margin-top: 5px;
  width: 20px;
  z-index: 20;
  padding-left: 100;
}
.bread-crumb {
  font-size: 0.7rem;
}
.search-string {
  font-weight: bold;
}
.sort-by {
  width: 340px;
}
.clear-filters {
  color: #1078a8 !important;
  font-weight: normal;
}
.clear-x {
  cursor: pointer;
}
.filter-badge-list {
  width: 340px;
}
.filter-badge-list a {
  color: white;
}

.parent {
  position: relative;
}
</style>
