<template>
  <div>
    <div class="popup">
      <div class="photo my-2">
        <img
          :src="featuredImageUrl"
          width="175px"
          height="100"
          :alt="'park ' + location.locationName + ' featured landscape'"
        />
      </div>
      <div class="d-flex">
        <div>
          <a @click="nameClicked" class="site-name">{{
            location.locationName
          }}</a>
          <p class="text-muted">{{ tenant.stateName }}</p>
        </div>
        <div class="ml-auto">
          <i
            class="fas fa-info-circle infoIcon text-primary"
            @click.stop="showDetailsPanel"
            v-b-modal="'modal-' + location.locationId"
            aria-label="Park Details"
          />
        </div>
      </div>
    </div>
    <LocationDetailsModal
      :locationId="location.locationId"
      :tenantId="location.tenantId"
      v-if="loadLocation"
    />
  </div>
</template>

<script>
import LocationDetailsModal from "@/components/search/LocationDetailsModal.vue";

export default {
  name: "LocationMarkerPopup",
  components: {
    LocationDetailsModal
  },
  props: {
    location: Object
  },
  data() {
    return {
      loadLocation: false
    };
  },
  methods: {
    nameClicked() {
      this.$router
        .push("/location/" + this.location.locationId)
        .catch(() => {});
    },
    showDetailsPanel() {
      this.loadLocation = true;
      // this.$bvModal.show("modal-" + this.location.id);
    }
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    featuredImageUrl() {
      return this.location.featuredImageUrl &&
        this.location.featuredImageUrl !== ""
        ? this.location.featuredImageUrl
        : this.tenant.defaultLocationImageUrl;
    }
  }
};
</script>

<style scoped>
.popup {
  width: 175px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.popup p {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}
.btn-min-width button {
  min-width: 112px;
}
.infoIcon {
  font-size: 1.1rem;
}
.infoIcon:focus,
.infoIcon:active {
  border: none !important;
  border-color: transparent !important;
}
</style>
