<template>
  <div>
    <a
      class="nav-link dropdown-toggle"
      href="javascript:void(0);"
      :id="title"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      >{{ title }} {{ countDisplay }}</a
    >
    <div
      class="dropdown-menu pb-0"
      @click="preventClose"
      :aria-label="title + ' dropdown'"
    >
      <div class="dropdown-item" v-for="option in options" :key="option.id">
        <label>
          <input
            type="checkbox"
            @change="toggleOption(option.id)"
            :checked="checked(option.id)"
          />
          {{ option[optionName] }}
        </label>
      </div>
      <a
        class="clear-filters ml-4 text-primary"
        @click="clearFilters"
        href="javascript:void(0);"
        >Clear filters</a
      >
      <a
        class="dropdown-item done mt-2 rounded-bottom"
        href="javascript:void(0);"
        :id="title + '-done'"
        >Done</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchFilter",
  props: {
    title: String,
    options: Array,
    optionName: String,
    filters: Array
  },
  data() {
    return {
      selectedOptions: []
    };
  },
  watch: {
    filters() {
      this.selectedOptions = this.filters;
    }
  },
  methods: {
    preventClose(e) {
      if (e.target.id === this.title + "-done") return;
      e.stopPropagation();
    },
    toggleOption(id) {
      if (this.selectedOptions.includes(id)) {
        this.selectedOptions = this.selectedOptions.filter(item => item !== id);
      } else {
        this.selectedOptions.push(id);
      }
      this.filtersUpdated();
    },
    checked(id) {
      if (this.selectedOptions.includes(id)) {
        return "checked";
      }
      return "";
    },
    clearFilters() {
      this.selectedOptions = [];
      this.filtersUpdated();
    },
    filtersUpdated() {
      this.$emit("filtersUpdated", this.selectedOptions);
    },
    setFilters() {
      this.selectedOptions = this.filters ? this.filters : [];
    }
  },
  computed: {
    countDisplay() {
      if (this.selectedOptions.length === 0) {
        return "";
      }
      return "(" + this.selectedOptions.length + ") ";
    }
  },
  mounted() {
    this.setFilters();
  }
};
</script>

<style scoped>
a {
  color: white;
}
.clear-filters {
  color: #1078a8;
}
.done {
  background-color: #1078a8;
  text-align: center;
}</style
>>
