<template>
  <div
    id="carouselIndicators"
    class="carousel slide"
    data-ride="carousel"
    v-if="location.longName"
  >
    <div class="carousel-top-grad"></div>
    <div class="carousel-bottom-grad"></div>
    <div class="carousel-inner">
      <div class="carousel-item active" v-if="this.location.images.length == 0">
        <img class="d-block w-100" :src="parkImage" alt="Slide 0" />
      </div>
      <div
        v-for="(photo, index) in this.location.images"
        :key="index"
        class="carousel-item"
        :class="getActiveImageClass(photo)"
      >
        <img
          class="d-block w-100"
          :src="photo.url"
          :alt="`Slide ${index + 1}`"
        />
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "LocationHeader",
  props: {
    location: Object
  },
  methods: {
    getActiveImageClass(photo) {
      if (this.parkImage == photo.url) {
        return "active";
      }
      return "";
    }
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    parkImage() {
      return this.location.featuredImageUrl &&
        this.location.featuredImageUrl !== ""
        ? this.location.featuredImageUrl
        : this.tenant.defaultLocationImageUrl;
    }
  }
};
</script>

<style scoped>
/* .carousel-inner {
  max-height: 400px;
  min-height: 100%;
} */
.carousel-text-overlay {
  position: absolute;
  bottom: 0em;
  left: 1em;
  color: white;
  z-index: 101;
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bold;
}

.carousel-top-grad {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 10;
}

.carousel-bottom-grad {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 10;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 50px;
  width: 50px;
}

/* .carousel-item {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
} */

.carousel-inner > .carousel-item > img,
.header .carousel,
.header .carousel-inner {
  min-height: 100%;
}
.carousel-item {
  overflow-y: hidden;
  max-height: 700px;
}
</style>
