<template>
  <div>
    <ValidationObserver ref="amenitiesForm">
      <a
        class="nav-link dropdown-toggle"
        href="javascript:void(0);"
        id="amenities-navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        >{{ "Amenities " }} {{ countDisplay }}</a
      >
      <div
        class="dropdown-menu dropdown-multicol2 pb-0"
        @click="preventClose"
        aria-labelledby="amenities-navbarDropdown"
      >
        <h6 class="dropdown-header">Park Amenities</h6>
        <div class="dropdown-col align-top">
          <div
            class="dropdown-item"
            v-for="point in displayedPointsOfInterest[0]"
            :key="point.id"
          >
            <label>
              <input
                type="checkbox"
                @change="togglePoi(point.id)"
                :checked="checkedPoi(point.id)"
              />
              {{ point.name }}
            </label>
          </div>
        </div>
        <div class="dropdown-col align-top">
          <div
            class="dropdown-item"
            v-for="point in displayedPointsOfInterest[1]"
            :key="point.id"
          >
            <label>
              <input
                type="checkbox"
                @change="togglePoi(point.id)"
                :checked="checkedPoi(point.id)"
              />
              {{ point.name }}
            </label>
          </div>
        </div>
        <div class="mb-4">
          <a
            class="show-all ml-4"
            href="javascript:void(0);"
            @click="showAllPointsOfInterest = !showAllPointsOfInterest"
          >
            {{
              this.showAllPointsOfInterest
                ? "Close amenities"
                : "Show all amenities"
            }}
            <i
              class="fas fa-chevron-down"
              v-if="!this.showAllPointsOfInterest"
            ></i>
            <i
              class="fas fa-chevron-up"
              v-if="this.showAllPointsOfInterest"
            ></i>
          </a>
        </div>
        <h6 class="dropdown-header">Spot Amenities</h6>
        <div class="dropdown-col align-top">
          <div
            class="dropdown-item"
            v-for="attr in boolAttributes[0]"
            :key="attr.id"
          >
            <label>
              <input
                type="checkbox"
                @change="toggle(attr.id)"
                :checked="checked(attr.id)"
              />
              {{ attr.displayName != null ? attr.displayName : attr.name }}
            </label>
          </div>
        </div>
        <div class="dropdown-col align-top">
          <div
            class="dropdown-item"
            v-for="attr in boolAttributes[1]"
            :key="attr.id"
          >
            <label>
              <input
                type="checkbox"
                @change="toggle(attr.id)"
                :checked="checked(attr.id)"
              />
              {{ attr.displayName != null ? attr.displayName : attr.name }}
            </label>
          </div>
        </div>
        <div v-if="showAllAttributes">
          <div
            class="form-group px-4"
            v-for="attr in selectAttributes"
            :key="attr.id"
          >
            <label :for="attr.id">{{
              attr.displayName != null ? attr.displayName : attr.name
            }}</label>
            <select
              class="form-control form-control-lg"
              :id="attr.id"
              @change="handleSelect"
              :value="setValue(attr.id)"
            >
              <option value>Select...</option>
              <option
                v-for="option in attr.attributeOptions"
                :key="option.id"
                :value="option.id"
                >{{
                  attr.id === 9 && option.id !== 91 && option.id !== 96
                    ? option.value + " & Up"
                    : option.value
                }}</option
              >
            </select>
          </div>
          <div
            class="form-group px-4"
            v-for="attr in multiSelectAttributes"
            :key="attr.id"
          >
            <label :for="attr.id">{{
              attr.displayName != null ? attr.displayName : attr.name
            }}</label>
            <multiselect
              :value="setMultiSelectValue(attr.id)"
              @input="handleMultiSelect"
              label="value"
              track-by="id"
              :multiple="true"
              :id="attr.id"
              :ref="attr.name"
              open-direction="bottom"
              placeholder="Select..."
              :options="attr.attributeOptions"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag" :key="option.id">
                  <span>{{ option.value }}</span>
                  <i
                    tabindex="1"
                    @keypress.enter.prevent="remove(option)"
                    @mousedown.prevent="remove(option)"
                    class="multiselect__tag-icon"
                  ></i>
                </span>
              </template>
            </multiselect>
          </div>
          <div
            class="form-group px-4"
            v-for="attr in numericAttributes"
            :key="attr.id"
          >
            <TextInput
              :rules="`numeric|minNumber:0`"
              :name="attr.displayName != null ? attr.displayName : attr.name"
              :id="attr.id"
              @change="handleInput"
              :value="setValue(attr.id)"
            />
          </div>
          <div
            class="form-group px-4"
            v-for="attr in textAttributes"
            :key="attr.id"
          >
            <label :for="attr.name">{{
              attr.displayName != null ? attr.displayName : attr.name
            }}</label>
            <input
              class="form-control form-control-lg"
              :id="attr.id"
              type="text"
              @change="handleInput"
              :value="setValue(attr.id)"
            />
          </div>
        </div>
        <div class="mb-4">
          <a
            class="show-all ml-4"
            href="javascript:void(0);"
            @click="showAllAttributes = !showAllAttributes"
          >
            {{
              this.showAllAttributes ? "Close amenities" : "Show all amenities"
            }}
            <i class="fas fa-chevron-down" v-if="!this.showAllAttributes"></i>
            <i class="fas fa-chevron-up" v-if="this.showAllAttributes"></i>
          </a>
        </div>
        <a
          class="clear-filters ml-4 text-primary"
          href="javascript:void(0);"
          @click="clearFilters"
          >Clear filters</a
        >
        <a
          class="dropdown-item done mt-2 rounded-bottom"
          href="javascript:void(0);"
          id="amenities-done"
          >Done</a
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "AmenitiesFilter",
  components: {
    Multiselect,
    TextInput,
    ValidationObserver
  },
  props: {
    pointsOfInterest: Array,
    attributes: Array
  },
  data() {
    return {
      showAllPointsOfInterest: false,
      showAllAttributes: false
    };
  },
  methods: {
    preventClose(e) {
      if (e.target.id === "amenities-done") return;
      e.stopPropagation();
    },
    clearFilters() {
      this.$store.commit("search/setSelectedPointsOfInterest", []);
      this.$store.commit("search/setSelectedAttributes", []);
    },
    togglePoi(id) {
      let pointsOfInterest = this.selectedPointsOfInterest;
      if (this.selectedPointsOfInterest.includes(id)) {
        pointsOfInterest = this.selectedPointsOfInterest.filter(
          item => item !== id
        );
      } else {
        pointsOfInterest.push(id);
      }
      this.$store.commit(
        "search/setSelectedPointsOfInterest",
        pointsOfInterest
      );
    },
    checkedPoi(id) {
      if (this.selectedPointsOfInterest.includes(id)) {
        return "checked";
      }
      return "";
    },
    toggle(id) {
      let attributes = this.selectedAttributes;
      if (this.selectedAttributes.map(x => x.id).includes(id)) {
        attributes = this.selectedAttributes.filter(item => item.id !== id);
      } else {
        attributes.push({
          id: id,
          value: "yes",
          selectedAttributeOptionId: []
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    checked(id) {
      if (this.selectedAttributes.map(x => x.id).includes(id)) {
        return "checked";
      }
      return "";
    },
    handleSelect(e) {
      let attributes = this.selectedAttributes;
      const inputValue = attributes.find(x => x.id === +e.target.id);
      if (inputValue) {
        if (e.target.value === "") {
          attributes = this.selectedAttributes.filter(
            item => item.id !== +e.target.id
          );
        } else {
          const index = attributes.indexOf(inputValue);
          inputValue.selectedAttributeOptions = [+e.target.value];
          attributes[index] = inputValue;
        }
      } else {
        attributes.push({
          id: +e.target.id,
          value: "null",
          selectedAttributeOptions: [+e.target.value]
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    handleMultiSelect(options, id) {
      let attributes = this.selectedAttributes.filter(item => item.id !== id);
      if (options.length > 0) {
        attributes.push({
          id: id,
          value: null,
          selectedAttributeOptions: options.map(x => x.id)
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    async handleInput(e) {
      const success = await this.$refs.amenitiesForm.validate();
      if (!success) return;

      let attributes = this.selectedAttributes.filter(
        item => item.id !== +e.target.id
      );
      if (e.target.value !== "") {
        attributes.push({
          id: +e.target.id,
          value: e.target.type === "text" ? e.target.value : +e.target.value,
          selectedAttributeOptions: []
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    setValue(id) {
      const attribute = this.selectedAttributes.find(x => x.id === id);
      if (!attribute) return "";
      return attribute.selectedAttributeOptions.length > 0
        ? attribute.selectedAttributeOptions[0]
        : attribute.value;
    },
    setMultiSelectValue(id) {
      const attribute = this.attributes.find(x => x.id === id);
      const selectedAttribute = this.selectedAttributes.find(x => x.id === id);
      if (selectedAttribute) {
        const options = attribute.attributeOptions.filter(o =>
          selectedAttribute.selectedAttributeOptions.includes(o.id)
        );
        return options;
      }
      return [];
    },
    setMultiSelectAutocomplete() {
      const self = this;
      this.multiSelectAttributes.forEach(x => {
        self.$refs[x.name][0].$refs.search.setAttribute("autocomplete", "off");
      });
    }
  },
  computed: {
    displayedPointsOfInterest() {
      let columnItemCount = Math.floor(this.pointsOfInterest.length / 2);
      let leftColumnItemCount =
        columnItemCount + (this.pointsOfInterest.length % 2);
      let rightColumnItemCount = columnItemCount;
      if (this.showAllPointsOfInterest) {
        return [
          this.pointsOfInterest.slice(0, leftColumnItemCount),
          this.pointsOfInterest.slice(
            leftColumnItemCount,
            leftColumnItemCount + rightColumnItemCount
          )
        ];
      }

      return [
        this.pointsOfInterest.slice(0, 2),
        this.pointsOfInterest.slice(
          leftColumnItemCount,
          leftColumnItemCount + 2
        )
      ];
    },
    boolAttributes() {
      const attributes = this.attributes.filter(
        x => x.attributeValueType.name === "Yes/No"
      );
      var half = Math.floor(attributes.length / 2);
      var remainder = attributes.length % 2;
      const columnBreak = remainder != 0 ? half + remainder : half;
      return [attributes.slice(0, columnBreak), attributes.slice(columnBreak)];
    },
    selectAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "Single Select"
      );
    },
    multiSelectAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "Multi Select"
      );
    },
    numericAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "Numeric Free"
      );
    },
    textAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "String Free"
      );
    },
    selectedPointsOfInterest() {
      const selected = this.$store.getters["search/selectedPointsOfInterest"];
      return selected ? selected : [];
    },
    selectedAttributes() {
      const selected = this.$store.getters["search/selectedAttributes"];
      return selected ? selected : [];
    },
    countDisplay() {
      if (
        this.selectedPointsOfInterest.length === 0 &&
        this.selectedAttributes.length === 0
      ) {
        return "";
      }
      return (
        "(" +
        (this.selectedPointsOfInterest.length +
          this.selectedAttributes.length) +
        ") "
      );
    }
  },
  watch: {
    showAllAttributes(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.setMultiSelectAutocomplete();
        });
      }
    }
  }
};
</script>

<style scoped>
.dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}
a {
  color: white;
}
.show-all {
  color: black;
}
.clear-filters {
  color: #1078a8;
}
.done {
  background-color: #1078a8;
  text-align: center;
}
div.dropdown-multicol2 {
  width: 28em;
}
div.dropdown-multicol2 > div.dropdown-col {
  display: inline-block;
  width: 50%;
}
</style>
