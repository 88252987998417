<template>
  <div>
    <a
      class="nav-link dropdown-toggle"
      href="javascript:void(0);"
      id="date-navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      >{{ "Dates " }}</a
    >
    <div
      class="dropdown-menu pb-0"
      @click="preventClose"
      aria-labelledby="date-navbarDropdown"
    >
      <ValidationObserver ref="dateFilter">
        <div class="m-2">
          <DateInput
            vid="arrivalDate"
            rules="isDate|arrivalDate"
            name="Arrival Date"
            id="startDate"
            :dateDisabledFn="minArrivalDate"
            v-model="startDate"
          />
        </div>
        <div class="m-2">
          <DateInput
            vid="departureDate"
            rules="isDate|departureDate:@arrivalDate"
            name="Departure Date"
            id="endDate"
            :dateDisabledFn="minDepartureDate"
            :initialDate="initialDate"
            v-model="endDate"
          />
        </div>
      </ValidationObserver>
      <a
        class="clear-filters ml-4 text-primary"
        href="javascript:void(0);"
        @click="clearFilters"
        >Clear filters</a
      >
      <a
        class="dropdown-item done mt-2 rounded-bottom"
        href="javascript:void(0);"
        id="dates-done"
        >Done</a
      >
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
export default {
  name: "DateFilter",
  components: {
    ValidationObserver,
    DateInput
  },
  methods: {
    preventClose(e) {
      if (e.target.id === "dates-done") return;
      e.stopPropagation();
    },
    clearFilters() {
      this.$store.commit("search/setSearchDate", {
        key: "startDate",
        value: null
      });
      this.$store.commit("search/setSearchDate", {
        key: "endDate",
        value: null
      });
    },
    minArrivalDate(ymd) {
      return moment().isAfter(ymd, "day");
    },
    minDepartureDate(ymd) {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY").isSameOrAfter(ymd, "day");
      } else {
        return moment().isSameOrAfter(ymd, "day");
      }
    }
  },
  computed: {
    startDate: {
      get() {
        return this.$store.getters["search/startDate"];
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        )
          this.$store.commit("search/setSearchDate", {
            key: "startDate",
            value: val
          });
      }
    },
    endDate: {
      get() {
        return this.$store.getters["search/endDate"];
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        )
          this.$store.commit("search/setSearchDate", {
            key: "endDate",
            value: val
          });
      }
    },
    initialDate() {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
.park-search-date {
  margin: 5px;
}
a {
  color: white;
}
.clear-filters {
  color: #1078a8;
}
.done {
  background-color: #1078a8;
  text-align: center;
}
.dropdown-menu {
  width: 200px;
}
</style>
