<template>
  <nav class="navbar navbar-expand-lg p-2 search-bar" role="navigation">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarFilterBarContent"
      aria-controls="navbarFilterBarContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarFilterBarContent">
      <div class="search-input mr-2">
        <ParkSearch
          mode="header"
          :includeMapBox="true"
          :includeParks="true"
          placeholder="Search..."
          @latLongUpdated="latLongUpdated"
        />
      </div>
      <ul class="navbar-nav mr-auto">
        <li class="dropdown ml-3">
          <DateFilter />
        </li>
        <li class="dropdown ml-3">
          <SearchFilter
            title="Spot Type"
            :options="spotTypes"
            optionName="name"
            @filtersUpdated="spotTypesUpdated"
            :filters="spotTypeFilters"
          />
        </li>
        <li class="dropdown ml-3">
          <SearchFilter
            title="Region"
            :options="regions"
            optionName="longName"
            @filtersUpdated="regionsUpdated"
            :filters="regionFilters"
          />
        </li>
        <li class="dropdown ml-3">
          <AmenitiesFilter
            :pointsOfInterest="pointsOfInterest"
            :attributes="attributes"
          />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import ParkSearch from "@/components/search/ParkSearch.vue";
import SearchFilter from "@/components/search/SearchFilter.vue";
import DateFilter from "@/components/search/DateFilter.vue";
import AmenitiesFilter from "@/components/search/AmenitiesFilter.vue";

export default {
  props: {
    spotTypes: Array,
    regions: Array,
    pointsOfInterest: Array,
    attributes: Array
  },
  components: {
    ParkSearch,
    SearchFilter,
    DateFilter,
    AmenitiesFilter
  },
  name: "SearchResultsFilters",
  methods: {
    regionsUpdated(regions) {
      this.$store.commit("search/setSelectedRegions", regions);
    },
    spotTypesUpdated(spotTypes) {
      this.$store.commit("search/setSelectedSpotTypes", spotTypes);
    },
    latLongUpdated() {
      this.$emit("latLongUpdated");
    }
  },
  computed: {
    spotTypeFilters() {
      return this.$store.getters["search/selectedSpotTypes"];
    },
    regionFilters() {
      return this.$store.getters["search/selectedRegions"];
    }
  }
};
</script>

<style scoped>
.search-bar {
  background-color: #404040;
  box-shadow: inset 0 6px 6px -6px #000000;
}
.search-input {
  min-width: 350px;
}
a {
  color: white;
}
</style>
