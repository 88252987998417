<template>
  <div class="float-left" v-if="icon">
    <i :class="icon" role="presentation" class="productTypeIcon pt-1 px-1" />

    <span class="count ml-1">{{ count }}</span>
  </div>
</template>

<script>
export default {
  name: "ProductTypeCount",
  props: {
    park: Object,
    groupName: String
  },
  computed: {
    productType() {
      const productType = this.park.productTypeCounts.filter(
        productType => productType.productTypeName == this.groupName
      );
      return productType;
    },
    count() {
      if (this.productType.length > 0) {
        return this.productType[0].count;
      }
      return 0;
    },
    icon() {
      if (this.productType && this.productType.length > 0) {
        return this.productType[0].productTypeIcon;
      }
      return "";
    }
  }
};
</script>

<style scoped>
.count {
  font-size: 1em;
  padding-right: 2em;
}
.productTypeIcon {
  font-size: 1.5rem;
  color: #ccc;
  vertical-align: middle;
}
</style>
