<template>
  <div class="map">
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :attributionControl="false"
      :zoom="6"
      :center="coordinates"
      ref="map"
      @load="onMapLoaded"
    >
      <MglNavigationControl position="top-right" />
      <MglGeolocateControl position="top-right" />
      <MglFullscreenControl position="top-right" />
      <MglScaleControl unit="imperial" />

      <MglMarker
        v-for="result in searchResults"
        :key="result.locationId"
        :coordinates="[result.mapLongitude, result.mapLatitude]"
        :color="markerColor(result)"
        :ref="'marker' + result.locationId"
      >
        <MglPopup @open="popupOpen(result)" class="popup">
          <LocationMarkerPopup :location="result" />
        </MglPopup>
        <div slot="marker" class="marker">
          <object
            class="svg"
            type="image/svg+xml"
            height="53px"
            width="35px"
            :data="require('@/assets/images/icons/' + getMarkerName(result))"
            :aria-label="result.locationName"
          ></object>
        </div>
      </MglMarker>
    </MglMap>
  </div>
</template>

<script>
import LocationMarkerPopup from "@/components/search/LocationMarkerPopup.vue";

import Mapbox from "mapbox-gl";
import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl,
  MglMarker,
  MglPopup
} from "vue-mapbox";

export default {
  name: "ParkMap",
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,
    MglMarker,
    MglPopup,
    LocationMarkerPopup
  },
  props: {
    searchResults: Array,
    hoveredLocationId: Number
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAP_API_KEY, // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/oneoutdoor/ckll1sar112r117p2rsbpbq3i" // your map style
    };
  },
  computed: {
    coordinates() {
      let latitude = this.$store.getters["tenant/tenantInfo"].latitude;
      let longitude = this.$store.getters["tenant/tenantInfo"].longitude;
      return [longitude, latitude];
    }
  },
  methods: {
    onMapLoaded() {
      this.pan();
    },
    pan() {
      let latitude = this.$store.getters["search/selectedLatitude"];
      let longitude = this.$store.getters["search/selectedLongitude"];
      if (
        latitude != null &&
        longitude != null &&
        this.$refs.map.actions.panTo
      ) {
        this.$refs.map.actions.panTo([longitude, latitude]).then(() => {
          this.$refs.map.actions.zoomTo(8);
        });
      }
    },
    getMarkerName(resultItem) {
      let markerName = "marker";
      if (resultItem.availabilityStatus == "Unavailable") {
        markerName = "marker_grey";
      }
      if (this.isHovered(resultItem.locationId)) {
        markerName += "_animated";
      }
      markerName += ".svg";

      return markerName;
    },
    markerColor(resultItem) {
      if (resultItem.meetsSearchCriteria == false) {
        return "gray";
      }
      return "blue";
    },
    isHovered(locationId) {
      if (locationId == this.hoveredLocationId) {
        return true;
      }
      return false;
    },
    popupOpen(result) {
      this.$emit("locationSelected", null);
      this.$emit("locationSelected", result.locationId);
      this.$refs.map.actions.panTo([result.mapLongitude, result.mapLatitude]);
    }
  },
  watch: {
    searchResults: {
      deep: true,
      handler() {
        this.pan();
      }
    }
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  }
};
</script>

<style scoped>
.map {
  height: 900px;
  width: auto;
  position: relative;
}

.svg {
  pointer-events: none;
}
.highlight {
  border-radius: 50%;
  margin-top: -2px;
  margin-left: 11px;
  height: 1px;
  width: 1px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }

  99% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
