<template>
  <div>
    <div
      :class="'justify-content-center mx-2 ' + statusClass + selected"
      :id="'location' + park.locationId"
    >
      <a
        class="item mt-3"
        @click="$emit('locationClicked', park.locationId)"
        @mouseover="hoverPark(park.locationId)"
        @mouseleave="clearHover(park.locationId)"
        href="javascript:void(0);"
        :style="'backgroundImage: url(' + parkImage + ');'"
        v-if="park.locationId"
      >
        <div>
          <div class="text-light pl-2 pt-2 mb-n4 item-text">
            {{ park.locationName.toUpperCase() }}
          </div>
          <div>
            <div class="text-light pl-2 pt-1 item-text">
              <i
                class="far fa-check-circle"
                v-if="park.availabilityStatus === 'Available'"
              ></i>
              <i class="fas fa-ban" v-else></i>
              {{ park.availabilityStatus.toUpperCase() }}
            </div>
          </div>
          <div>
            <div class="text-light counts">
              <ProductTypeCount :park="park" groupName="Cabin" />
              <ProductTypeCount :park="park" groupName="Campsite" />
              <ProductTypeCount :park="park" groupName="Group Campsite" />
              <ProductTypeCount :park="park" groupName="Shelter" />
            </div>
          </div>
        </div>
      </a>
      <div class="row">
        <div class="col col-6 pr-0">
          <button
            type="button"
            class="btn btn-gray w-100 btn-square"
            @click.stop="showDetailsPanel"
            v-b-modal="'modal-' + park.locationId"
            :aria-label="'View Park Details for ' + park.locationName"
          >
            <span class="mr-auto">Quick View</span
            ><i class="my-auto fas fa-info-circle ml-1"></i>
          </button>
        </div>

        <div class="col col-6 pl-0">
          <button
            type="button"
            class="btn btn-primary w-100 btn-square"
            @click="
              $router.push(`/location/${park.locationId}`).catch(() => {})
            "
            :aria-label="'Select Park ' + park.locationName"
          >
            <span class="mr-auto">Select Park</span
            ><i class="my-auto fas fa-arrow-circle-right ml-1"></i>
          </button>
        </div>
      </div>
    </div>
    <div>
      <LocationDetailsModal
        :locationId="park.locationId"
        :tenantId="park.tenantId"
        v-if="loadLocation"
      />
    </div>
  </div>
</template>

<script>
import ProductTypeCount from "@/components/search/ProductTypeCount.vue";
import LocationDetailsModal from "@/components/search/LocationDetailsModal.vue";

export default {
  name: "ParkSearchResultsListItem",
  props: {
    park: Object,
    isSelected: Boolean
  },
  data() {
    return {
      loadLocation: false
    };
  },
  components: {
    ProductTypeCount,
    LocationDetailsModal
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    statusClass() {
      let statusClass = "";
      if (
        this.park.meetsSearchCriteria === false ||
        this.park.availabilityStatus === "Unavailable"
      ) {
        statusClass = "inactive";
      }

      return statusClass;
    },
    selected() {
      if (this.isSelected === true) {
        return "selected";
      } else {
        return "";
      }
    },
    parkImage() {
      return this.park.featuredImageUrl && this.park.featuredImageUrl !== ""
        ? this.park.featuredImageUrl
        : this.tenant.defaultLocationImageUrl;
    }
  },
  methods: {
    showDetailsPanel() {
      this.loadLocation = true;
      //this.$bvModal.show("modal-" + this.park.locationId);
    },
    hoverPark(locationId) {
      this.$emit("hoverPark", locationId);
    },
    clearHover() {
      this.$emit("hoverPark", null);
    }
  },
  watch: {
    isSelected: {
      handler() {
        if (this.isSelected === true) {
          let element = document.getElementById(
            "location" + this.park.locationId
          );
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
        }
      }
    }
  }
};
</script>
<style scoped>
.item {
  box-shadow: inset 1px 40px 30px -6px black;
  min-height: 175px;
  max-height: 200px;
  min-width: 340px;
  max-width: 100%px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  font-size: 0.8rem;
  font-weight: bold;
  display: block;
}

.counts {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: absolute;
  bottom: 0;
  line-height: 1.5em;
  padding: 0.25em 1em;
}

.inactive {
  position: static; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  background-color: #fff;
  opacity: 0.25;
}

.item-text {
  display: inline-block;
}

.selected {
  outline: 4px solid #1078a8;
}
</style>
