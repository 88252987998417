<template>
  <b-modal
    :id="'modal-' + locationId"
    size="lg"
    header-class="border-0 h-25 pb-0"
    footer-class="border-0"
    body-class="p-0"
  >
    <template v-slot:modal-header="{ close }" v-if="location">
      <div class="container-fluid">
        <div class="row">
          <div class="col-9">
            <h3 class="spot-detail-header">{{ location.longName }}</h3>
          </div>
          <div class="col-3">
            <a
              role="button"
              href="javascript:void(0);"
              @click="close"
              class="float-right"
            >
              Close
              <i class="fas fa-times"></i>
            </a>
          </div>
        </div>
      </div>
    </template>
    <div class="text-center" v-if="!location">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div class="header">
      <!-- <LocationHeader :location="location" /> -->
    </div>
    <div class="item-details p-3" v-if="location">
      <LocationInformation :location="location" :pois="pois" />
    </div>
    <template v-slot:modal-footer>
      <div></div>
    </template>
  </b-modal>
</template>

<script>
import LocationService from "@/services/LocationService.js";
import LocationInformation from "@/components/parkdetails/LocationInformation.vue";

export default {
  name: "LocationDetailsModal",
  components: {
    LocationInformation
  },
  data() {
    return {
      location: null,
      pois: []
    };
  },
  props: {
    locationId: Number
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    loadLocation() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getLocationDetails().then(response => {
        this.location = response;
      });
    },
    loadPois() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getPointsOfInterestForLocation().then(response => {
        this.pois = response.locationPointsOfInterest;
      });
    }
  },
  mounted() {
    this.loadLocation();
    this.loadPois();
  }
};
</script>

<style scoped>
.modal-header {
  padding: 0px;
}
.item-detail-header {
  font-weight: bold;
  border-bottom: 1px solid black;
}
.attribute-icons {
  color: #999;
  font-size: 0.8rem;
}
.attribute-values {
  text-align: left;
}
.odd {
  background-color: #eee;
}
.spot-detail-header {
  color: #1078a8;
  font-weight: bold !important;
}
.area-header {
  font-weight: bold;
}
.area-description {
  color: #707070;
  font-size: initial;
}
.spot-header-line {
  font-weight: bold;
  font-size: large;
}
</style>
