import LocationApi from "@/generatedapiclients/src/api/LocationApi.js";
import SpotApi from "@/generatedapiclients/src/api/SpotApi.js";
import AdminSpotApi from "@/generatedapiclients/src/api/AdminSpotApi.js";

export default class LocationService {
  constructor(tenantId, locationId) {
    this._tenantId = tenantId;
    this._locationId = locationId;
    this._locationApi = new LocationApi();
    this._spotApi = new SpotApi();
    this._adminSpotApi = new AdminSpotApi();
  }
  getAllLocations() {
    return this._locationApi.v1TenantTenantIdLocationGet(this._tenantId);
  }

  getLocationsForRegion(regionId) {
    return this._locationApi.v1TenantTenantIdRegionRegionIdLocationsGet(
      regionId,
      this._tenantId
    );
  }

  getLoopsForLocation() {
    return this._locationApi.v1TenantTenantIdLocationLocationIdLoopGet(
      this._locationId,
      this._tenantId
    );
  }
  getLocationDetails() {
    return this._locationApi.v1TenantTenantIdLocationLocationIdGet(
      this._locationId,
      this._tenantId
    );
  }
  getSpotsForLocation() {
    return this._spotApi.v1TenantTenantIdLocationLocationIdSpotGet(
      this._locationId,
      this._tenantId
    );
  }
  getPointsOfInterestForLocation() {
    return this._locationApi.v1TenantTenantIdLocationLocationIdPointsofinterestGet(
      this._locationId,
      this._tenantId
    );
  }

  spotSearchForLocation(spotAvailabilityRequest) {
    if (spotAvailabilityRequest.adminView)
      return this.adminSpotSearchForLocation(spotAvailabilityRequest);

    return this._spotApi
      .v1TenantTenantIdSpotAvailabilityPost(this._tenantId, {
        spotAvailabilityRequest
      })
      .then(response => {
        if (response.spots) {
          response.spots.forEach(element => {
            element.isSelected = false;
          });
        }
        return response;
      });
  }

  adminSpotSearchForLocation(request) {
    const spotAvailabilityRequest = {
      pastDatesAvailable: true,
      ...request
    };
    return this._adminSpotApi
      .v1TenantTenantIdAdminAdminSpotAvailabilityPost(this._tenantId, {
        spotAvailabilityRequest
      })
      .then(response => {
        if (response.spots) {
          response.spots.forEach(element => {
            element.isSelected = false;
          });
        }
        return response;
      });
  }
  async getSpotDetails(spotId) {
    return this._spotApi.v1TenantTenantIdSpotSpotIdGet(spotId, this._tenantId);
  }
  async getClassificationsWithSpotTypes() {
    return await this._spotApi.v1TenantTenantIdSpotProductClassificationsGet(
      this._tenantId,
      { locationId: this._locationId }
    );
  }
  filterClassificationsFromSpotTypes(spotTypes, classes) {
    if (!spotTypes || spotTypes.length == 0) {
      return classes;
    }
    const filteredClasses = classes.filter(c => {
      const ids = c.spotTypes.map(st => st.id);
      const spotTypeIncluded = ids.some(r => spotTypes.indexOf(r) >= 0);
      return spotTypeIncluded;
    });
    return filteredClasses;
  }

  filterResults(
    searchResults,
    selectedSpotTypes,
    selectedProductClassifications,
    selectedAttributes,
    selectedSpotId
  ) {
    let spotFilterResults = this.spotTypeFilter(
      searchResults,
      selectedSpotTypes,
      selectedSpotId
    );
    let productFilterResults = this.productClassificationFilter(
      spotFilterResults,
      selectedProductClassifications,
      selectedSpotId
    );
    let attributeFilterResults = this.attributeFilter(
      productFilterResults,
      selectedAttributes,
      selectedSpotId
    );
    return attributeFilterResults;
  }
  spotTypeFilter(searchResults, selectedSpotTypes, selectedSpotId) {
    if (!searchResults) {
      return [];
    }
    if (!selectedSpotTypes || selectedSpotTypes.length === 0) {
      return searchResults;
    } else {
      let displayedResults = searchResults.filter(item => {
        return (
          item.spotTypes.filter(spotType =>
            selectedSpotTypes.includes(spotType.id)
          ).length > 0 || item.id === selectedSpotId
        );
      });
      return displayedResults;
    }
  }
  productClassificationFilter(
    searchResults,
    selectedProductClassifications,
    selectedSpotId
  ) {
    if (
      !selectedProductClassifications ||
      selectedProductClassifications.length === 0
    ) {
      return searchResults;
    }
    let displayedResults = searchResults.filter(item => {
      return (
        item.id === selectedSpotId ||
        selectedProductClassifications.includes(
          item.product.productClassificationId
        )
      );
    });
    return displayedResults;
  }
  attributeFilter(searchResults, selectedAttributes, selectedSpotId) {
    if (
      !searchResults ||
      !selectedAttributes ||
      selectedAttributes.length === 0
    ) {
      return searchResults;
    }
    let displayedResults = searchResults.filter(
      spot =>
        selectedAttributes.some(attr => {
          if (attr.value != null && attr.value != "null") {
            return spot.spotAttributes.some(
              spotAttr =>
                spotAttr.id == attr.id &&
                spotAttr.value.toLowerCase() ===
                  attr.value.toString().toLowerCase()
            );
          } else if (attr.selectedAttributeOptions.length > 0) {
            return spot.spotAttributes.some(
              spotAttr =>
                spotAttr.id == attr.id &&
                spotAttr.selectedAttributeOptions &&
                spotAttr.selectedAttributeOptions.some(id =>
                  attr.id === 9
                    ? id >= attr.selectedAttributeOptions[0] && id <= 96
                    : attr.selectedAttributeOptions.includes(id)
                )
            );
          }
        }) || spot.id === selectedSpotId
    );
    return displayedResults;
  }
  getIconAttributesToDisplay(spot) {
    if (!spot.spotAttributes || spot.spotAttributes.length == 0) {
      return [];
    }
    let filtered = spot.spotAttributes.filter(
      attr =>
        (attr.value || attr.selectedAttributeOptions?.length > 0) &&
        attr.showIcon == true &&
        attr.hasNoValue == false &&
        attr.value.toLowerCase() != "n" &&
        attr.value.toLowerCase() != "no" &&
        attr.value.toLowerCase() != "none"
    );
    return filtered;
  }
}
