<template>
  <div class="container-fluid px-0">
    <label for="searchBar" class="sr-only" style="color:white;">Search</label>
    <input
      type="text"
      id="searchBar"
      v-model="searchTerm"
      class="form-control form-control-lg"
      :placeholder="placeholder"
    />
    <div :class="resultsWrapperClass">
      <div
        class="card text-left"
        v-if="nameSearchResults.results !== null && includeParks"
      >
        <div class="card-header">Parks</div>
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item"
            v-if="nameSearchResults.results.length == 0"
          >
            No parks found
          </li>
          <li
            class="list-group-item"
            v-for="result in nameSearchResults.results"
            :key="result.locationId"
          >
            <a class="text-primary" @click="selectedPark(result)">
              {{
                result.matchingAreaName ? result.matchingAreaName + " in " : ""
              }}{{ result.locationName }}
            </a>
          </li>
        </ul>
      </div>
      <div
        class="card text-left"
        v-if="mapboxSearchResults.results !== null && includeMapBox"
      >
        <div class="card-header">Locations</div>
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item"
            v-if="mapboxSearchResults.results.length == 0"
          >
            No locations found
          </li>
          <li
            class="list-group-item"
            v-for="result in mapboxSearchResults.results"
            :key="result.id"
          >
            <a class="text-primary" @click="selectedPlace(result)">{{
              result.place_name
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParkSearch",
  data() {
    return {
      mapboxSearchResults: { results: null },
      nameSearchResults: { results: null },
      searchTerm: "",
      delayTimer: null,
      resultsWrapperClass: "results-wrapper-primary-search"
    };
  },
  props: {
    mode: String,
    placeholder: String,
    includeMapBox: Boolean,
    includeParks: Boolean
  },
  watch: {
    searchTerm() {
      this.searchDelay();
    }
  },
  methods: {
    searchDelay() {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(this.search, 500); // Wait to query backend to reduce unecessary calls
    },
    search() {
      if (this.searchTerm == "") {
        this.mapboxSearchResults.results = null;
        this.nameSearchResults.results = null;
      } else {
        this.$store
          .dispatch("search/searchMapbox", this.searchTerm)
          .then(response => {
            this.mapboxSearchResults.results = response;
          });
        this.$store
          .dispatch("search/searchParks", {
            locationName: this.searchTerm,
            pageNumber: 1,
            itemsPerPage: 10
          })
          .then(response => {
            this.nameSearchResults.results = response;
          });
      }
    },
    selectedPlace(selectedItem) {
      this.$store.commit("search/setSelectedLatitude", selectedItem.center[1]);
      this.$store.commit("search/setSelectedLongitude", selectedItem.center[0]);
      this.$store.commit("search/setSearchTerm", selectedItem.text);
      this.searchTerm = "";
      this.navigateToResults();
      this.$emit("latLongUpdated");
    },
    selectedPark(selectedItem) {
      this.searchTerm = "";
      this.navigateToPark(selectedItem.locationId);
    },
    navigateToResults() {
      this.$router.push("/parksearchresults").catch(() => {});
    },
    navigateToPark(locationId) {
      this.$router.push(`/location/${locationId}`).catch(() => {});
    }
  },
  created() {
    if (this.mode == "header") {
      this.resultsWrapperClass = "results-wrapper-filter-bar";
    } else {
      this.resultsWrapperClass = "results-wrapper-primary-search";
    }
  }
};
</script>

<style scoped>
#searchBar {
  border-radius: 0%;
}
.card {
  width: 100%;
  margin-bottom: 0px;
  border-radius: 0px;
}
.card-header {
  background-color: #ccc;
  padding: 0.25rem 1.25rem;
  border-radius: 0px;
}
.results-wrapper-filter-bar {
  max-height: 400px;
  width: 100%;
  max-width: 450px;
  z-index: 1000;
  overflow: visible;
  position: absolute;
}

@media (max-width: 992px) {
  .results-wrapper-filter-bar {
    top: 80px;
  }
}

@media (min-width: 991px) {
  .results-wrapper-filter-bar {
    top: 50px;
  }
}

.results-wrapper-primary-search {
  max-height: 300px;
  width: 100%;
  z-index: 10;
  position: relative;
  overflow: auto;
}
</style>
