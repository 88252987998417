<template>
  <div>
    <div>
      <SearchResultsFilters
        @latLongUpdated="latLongUpdated"
        :spotTypes="spotTypes"
        :regions="regions"
        :pointsOfInterest="pointsOfInterest"
        :attributes="attributes"
      />
      <ParkSearchResultsList
        :searchResults="searchResults"
        :spotTypes="spotTypes"
        :regions="regions"
        :pointsOfInterest="pointsOfInterest"
        :attributes="attributes"
        @hoverPark="hoverParkUpdated"
        @sort="latLongUpdated"
        @locationClicked="locationClicked"
        :selectedLocationId="selectedLocationId"
      />
      <div class="map" role="main">
        <ParkMap
          ref="parkMap"
          :searchResults="searchResults"
          :hoveredLocationId="hoveredLocationId"
          @locationSelected="locationSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchResultsFilters from "@/components/search/SearchResultsFilters.vue";
import ParkSearchResultsList from "@/components/search/ParkSearchResultsList.vue";
import ParkSearchService from "@/services/ParkSearchService.js";
import ParkMap from "@/components/search/ParkMap.vue";

export default {
  name: "ParkSearchResults",
  title: "Park Search Results",
  data() {
    return {
      searchResults: [],
      spotTypes: [],
      regions: [],
      pointsOfInterest: [],
      attributes: [],
      hoveredLocationId: null,
      selectedLocationId: null
    };
  },
  components: {
    SearchResultsFilters,
    ParkSearchResultsList,
    ParkMap
  },
  methods: {
    //...mapGetters("tenant", ["selectedLatitude", "selectedLogintude", "selectedSpotTypes"]),
    locationClicked(e) {
      this.searchResults.forEach(park => {
        if (
          park.locationId == e &&
          !this.$refs.parkMap.$refs[`marker${e}`][0].marker.getPopup().isOpen()
        ) {
          this.$refs.parkMap.$refs[`marker${e}`][0].togglePopup();
        } else if (
          park.locationId != e &&
          this.$refs.parkMap.$refs[`marker${park.locationId}`][0].marker
            .getPopup()
            .isOpen()
        ) {
          this.$refs.parkMap.$refs[`marker${park.locationId}`][0].togglePopup();
        }
      });
    },
    search() {
      let latitude = this.$store.getters["search/selectedLatitude"];
      let longitude = this.$store.getters["search/selectedLongitude"];
      if (latitude != null && longitude != null) {
        //Get locations near provided lat/long
        this.$store
          .dispatch("search/searchParksByLocation", {
            latitude: latitude,
            longitude: longitude,
            pageNumber: 1,
            itemsPerPage: 100
          })
          .then(response => {
            this.searchResults = response.locationSummaryItemsPage.data;
          });
      } else {
        this.$store
          .dispatch("search/searchParks", {
            startDate: this.startDate,
            endDate: this.endDate,
            spotTypeIds: this.selectedSpotTypes,
            regionIds: this.selectedRegions,
            pointsOfInterestIds: this.selectedPointsOfInterest,
            spotAttributes: this.selectedAttributes,
            pageNumber: 1,
            itemsPerPage: 100
          })
          .then(response => {
            this.searchResults = response.sort((a, b) => {
              if (a.availabilityStatus === b.availabilityStatus) {
                return 0;
              } else if (a.availabilityStatus == "Available") {
                return -1;
              } else {
                return 1;
              }
            });
          });
      }
    },
    async getSpotTypes() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      const results = await parkSearchService.getSpotTypes();
      this.spotTypes = results.filter(x => x.name !== "Accessible");
    },
    async getRegions() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      this.regions = await parkSearchService.getRegions();
    },
    async getPointsOfInterest() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      const results = await parkSearchService.getPointsOfInterest({
        isSearchable: true
      });
      this.pointsOfInterest = results;
    },
    async getAttributes() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      this.attributes = await parkSearchService.getAttributes({
        isSearchable: true
      });
    },
    latLongUpdated() {
      this.search();
    },
    hoverParkUpdated(locationId) {
      this.hoveredLocationId = locationId;
    },
    locationSelected(locationId) {
      this.selectedLocationId = locationId;
    }
  },
  mounted() {
    this.getSpotTypes();
    this.getRegions();
    this.getPointsOfInterest();
    this.getAttributes();
    window.scrollTo(0, 0);
    this.search();
  },
  computed: {
    startDate() {
      return this.$store.getters["search/startDate"];
    },
    endDate() {
      return this.$store.getters["search/endDate"];
    },
    selectedSpotTypes() {
      const selectedSpotTypes = this.$store.getters["search/selectedSpotTypes"];
      return !selectedSpotTypes ? [] : selectedSpotTypes;
    },
    selectedRegions() {
      const selectedRegions = this.$store.getters["search/selectedRegions"];
      return !selectedRegions ? [] : selectedRegions;
    },
    selectedPointsOfInterest() {
      const selectedPointsOfInterest = this.$store.getters[
        "search/selectedPointsOfInterest"
      ];
      return !selectedPointsOfInterest ? [] : selectedPointsOfInterest;
    },
    selectedAttributes() {
      const selectedAttributes = this.$store.getters[
        "search/selectedAttributes"
      ];
      return !selectedAttributes ? [] : selectedAttributes;
    }
  },
  watch: {
    selectedSpotTypes() {
      this.search();
    },
    selectedRegions() {
      this.search();
    },
    selectedPointsOfInterest() {
      this.search();
    },
    selectedAttributes: {
      deep: true,
      handler() {
        this.search();
      }
    },
    startDate() {
      this.search();
    },
    endDate() {
      this.search();
    }
  }
};
</script>
<style scoped>
@media (max-width: 700px) {
  .map {
    display: none;
  }
}

@media (min-width: 600px) {
  .map {
    display: block;
  }
}
</style>
